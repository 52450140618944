import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PlayIcon from "../../assets/images/icons/play-icon.png";

const GameModal = ({ selectedGame, setSelectedGame, show, setShow }) => {
  const handleShow = (game) => {
    setSelectedGame(game);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedGame(null);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      centered
      className="game-modal"
    >
      {/* <Modal.Header closeButton style={{ background: "#FFF" }}></Modal.Header> */}
      <Modal.Body>
        <div className="content">
          <h5>Bet Limit</h5>

          <p>
            <span>Minimum</span> &nbsp;100 &nbsp; | &nbsp; <span>Maximum </span>
            &nbsp;2,50,000
          </p>
          <p>
            <span>Max Payout</span> &nbsp;5,00,000
          </p>
          <div className="play-btn">
            <a href={selectedGame?.redirectUrl}>
              Continue to Game Play
              <div className="img-box">
                <img src={PlayIcon} alt="play" />
              </div>
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GameModal;
